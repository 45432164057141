import React, {useEffect, useState} from 'react';
import {EventService, FooterEvent} from '@proxibid/proxiponents';
import CategoryFilter from './category-filter';
import {makeStyles} from '@material-ui/styles';
import Box from '@material-ui/core/Box';

export default function StickyCategoryFilter(props) {
    const [stickAboveFooter, setStickAboveFooter] = useState(true);
    const classes = useStyles();

    useEffect(() => {
        EventService.addEventListener(FooterEvent.SHOW_FOOTER, showFooterHandler);
        EventService.addEventListener(FooterEvent.HIDE_FOOTER, hideFooterHandler);

        return () => {
            EventService.removeEventListener(FooterEvent.SHOW_FOOTER, showFooterHandler);
            EventService.removeEventListener(FooterEvent.HIDE_FOOTER, hideFooterHandler);
        };
    });

    useEffect(() => {
        // Fixed position needs to be done on the parent div, otherwise we aren't relative to the body correctly. Couldn't find another cleaner way to do this. Sorry.
        const parentElement = document.getElementById('stickyCategoryFilter').parentElement;
        if (stickAboveFooter) {
            parentElement.classList.remove(classes.fixed);
            parentElement.classList.add(classes.sticky);
        } else {
            parentElement.classList.remove(classes.sticky);
            parentElement.classList.add(classes.fixed);
        }
    }, [stickAboveFooter]);

    return (
        <Box id='stickyCategoryFilter'>
            <CategoryFilter/>
        </Box>
    );

    function showFooterHandler() {
        setStickAboveFooter(true);
    }

    function hideFooterHandler() {
        setStickAboveFooter(false);
    }
}

const useStyles = makeStyles({
    sticky: {
        position: 'sticky',
        bottom: 0,
        zIndex: 10,
    },
    fixed: {
        position: 'fixed',
        bottom: 0,
        width: '100%'
    }
});

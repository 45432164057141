import React, {useEffect, useState} from 'react';
import {Button, EventService, FooterEvent, HeaderEvent} from '@proxibid/proxiponents';

export default function CategoryFilter(props) {
    const [filterOpen, setFilterOpen] = useState(false);

    useEffect(() => {
        EventService.addEventListener('setFilterOpen', updateFilterFromEvent);
        EventService.addEventListener('categoryFilterClicked', updateFilterFromEvent);

        return function cleanup() {
            EventService.removeEventListener('setFilterOpen', updateFilterFromEvent);
            EventService.removeEventListener('categoryFilterClicked', updateFilterFromEvent);
        };
    });

    useEffect(() => {
        if (filterOpen) {
            EventService.dispatchEvent(HeaderEvent.HIDE_HEADER);
            EventService.dispatchEvent(FooterEvent.HIDE_FOOTER);
        } else {
            EventService.dispatchEvent(HeaderEvent.SHOW_HEADER);
            EventService.dispatchEvent(FooterEvent.SHOW_FOOTER);
        }
    }, [filterOpen]);

    return (
        <Button variant="contained" color={!filterOpen ? 'secondary' : 'primary'}
                style={{width: '100%', borderRadius: '0px'}} onClick={click}>
            {filterOpen ? 'Done' : 'Filter'}
        </Button>
    );

    function updateFilterFromEvent(e) {
        setFilterOpen(e.detail.filterOpen);
    }

    function click() {
        const updatedFilterOpen = !filterOpen;
        setFilterOpen(updatedFilterOpen);
        EventService.dispatchEvent('categoryFilterClicked', {filterOpen: updatedFilterOpen});

    }
}
